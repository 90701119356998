module.exports = {
  comm: {
    home: 'หน้าหลัก',
    pricing: 'ราคา',
    blog: 'การโคลนเสียงและ API', // อัปเดต
    language: 'ภาษา:',
    select_language: 'เลือกภาษา',
    txt2voice: 'ข้อความเป็นเสียง',
    voice2txt: 'เสียงเป็นข้อความ',
    voiceclone: 'โคลนเสียง',
    video2txt: 'วิดีโอเป็นข้อความ',
    footer_help: 'ต้องการความช่วยเหลือ? ส่งอีเมลถึงเรา:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'เข้าสู่ระบบ',
    login_desc: 'เข้าสู่ระบบเพื่อเข้าถึงบัญชี TikTok Voice Generator ของคุณ',
    logout: 'ออกจากระบบ',
  },
  txt2voice: {
    title: 'ตัวสร้างเสียง TikTok: สร้างเสียง AI TikTok ที่สนุกสนาน',
    description: 'สร้างและดาวน์โหลดเสียง AI TikTok ที่สนุกสนานได้ฟรี เช่น เสียงของ Jessie, เสียงของ C3PO, เสียงของ ghostface...',
    keywords: 'ตัวสร้างเสียง TikTok, เสียง AI TikTok, ตัวสร้างเสียง Tik Tok',
    main_title_p01: 'ตัวสร้างเสียง TikTok',
    main_title_p02: 'สร้างเสียง TikTok ที่สนุกสนาน: เสียงของ Jessie, เสียงของ C3PO, เสียงของ ghostface',
    main_textarea_holder: 'พิมพ์หรือวางข้อความที่นี่',
    main_genvoice: 'สร้าง',
    main_generating: 'กำลังสร้าง',
    main_input_empty: 'กรุณาพิมพ์หรือวางข้อความ',
    daily_usage_limit_msg: '1) คุณถึงขีดจำกัดการใช้งานสูงสุดของวันนี้แล้ว กรุณากลับมาใหม่ในวันพรุ่งนี้ 2) หากต้องการใช้งานเพิ่มในกรณีเร่งด่วน โปรดติดต่อเราทางอีเมล',
    text_max_prompt1: 'กรุณาจำกัดจำนวนคำที่',
    text_max_prompt2: 'คำหรือน้อยกว่า!',
    popup_nologin_title: 'อัปเกรดแผนการสมัครสมาชิกของคุณ',
    popup_nologin_desc: 'ปลดล็อกเวลาการสร้างเสียงเพิ่มเติมโดยการอัปเกรดเป็นการสมัครสมาชิกพรีเมียม',
    popup_nologin_btntext: 'สำรวจแผนการสมัครสมาชิก',
    popup_nosub_title: 'อัปเกรดแผนการสมัครสมาชิกของคุณ',
    popup_nosub_desc: 'ปลดล็อกเวลาการสร้างเสียงเพิ่มเติมโดยการอัปเกรดเป็นการสมัครสมาชิกพรีเมียม',
    popup_nosub_btntext: 'สำรวจแผนการสมัครสมาชิก',
    popup_sublimit_title: 'การสมัครสมาชิกหมดอายุ กรุณาอัปเกรด',
    popup_sublimit_desc: 'ปรับปรุงประสบการณ์ของคุณด้วยฟีเจอร์พรีเมียมและการเข้าถึงไม่จำกัด',
    popup_sublimit_btntext: 'ไปที่การซื้อเพิ่มเติม',
  },
  pricing: {
    new_features_alert: "📣 เราได้เปิดตัวบริการ 🎙️ การโคลนเสียงและ 🤖 API สำหรับสมาชิกแบบชำระเงิน! (หลังจากชำระเงิน กรุณาส่งอีเมลไปที่ tiktokaivoicetool@gmail.com เพื่อขอความช่วยเหลือเกี่ยวกับฟีเจอร์เหล่านี้) นอกจากนี้ คุณสามารถส่งอีเมลถึงเราได้อย่างอิสระสำหรับคำขอการปรับแต่งใดๆ 😃", // เพิ่มใหม่
    pricing_title: 'จากผู้สร้างคอนเทนต์รายบุคคลไปจนถึงองค์กรขนาดใหญ่ที่สุด เรามีแผนสำหรับคุณ',
    pricing_desc: 'เสียงธรรมชาติ เหมือนมนุษย์มากกว่า 100 เสียง มากกว่า 15 ภาษา และราคาเกือบต่ำที่สุดสำหรับทุกคน',
    sub_free_name: 'ฟรี',
    sub_free_desc: 'สำหรับบุคคลที่ต้องการลองใช้เสียง AI ที่ล้ำสมัยที่สุด',
    sub_free_content: [
      'จำกัด 1000 ตัวอักษรต่อเดือน',
      '5 การสร้างต่อวัน',
    ],
    sub_starter_name: 'เริ่มต้น',
    sub_starter_desc: 'สำหรับผู้สร้างที่ผลิตเนื้อหาระดับพรีเมียมสำหรับผู้ชมทั่วโลก',
    sub_starter_content: [
      '1 เสียงโคลน',
      'จำกัด 300,000 ตัวอักษรต่อเดือน (ประมาณ 7 ชั่วโมงของเสียง)',
      'ดาวน์โหลดไม่จำกัด',
      'การสนับสนุนทางอีเมลภายใน 72 ชั่วโมง',
      'การเข้าถึงเสียงและฟีเจอร์ใหม่ก่อนใคร',
    ],
    sub_pro_name: 'โปร',
    sub_pro_desc: 'สำหรับผู้สร้างที่ขยายการผลิตเนื้อหา',
    sub_pro_content: [
      'การเข้าถึง API',
      '3 เสียงโคลน',
      'จำกัด 1,000,000 ตัวอักษรต่อเดือน (ประมาณ 24 ชั่วโมงของเสียง)',
      'ดาวน์โหลดไม่จำกัด',
      'การสนับสนุนทางอีเมลภายใน 48 ชั่วโมง',
      'การเข้าถึงเสียงและฟีเจอร์ใหม่ก่อนใคร',
    ],
    period_year: 'รายปี',
    period_month: 'รายเดือน',
    period_month_short: 'เดือน',
    billed_year: 'เรียกเก็บเงินรายปี',
    most_popular: 'ยอดนิยมที่สุด',
    discount_quantity: 'ประหยัด $48',
    buy_btntext: 'เริ่มต้น',
    pay_succ: 'การชำระเงินสำเร็จ!',
    pay_succ_desc: 'ขอบคุณสำหรับการซื้อของคุณ การทำธุรกรรมของคุณได้รับการประมวลผลเรียบร้อยแล้ว',
    pay_succ_btntext: 'เริ่มใช้ TikTok Voice',
    pay_fail: 'การชำระเงินล้มเหลว!',
    pay_fail_desc: "ขออภัย เราไม่สามารถประมวลผลการทำธุรกรรมของคุณได้ กรุณาลองอีกครั้งหรือติดต่อทีมสนับสนุนของเรา (tiktokaivoicetool@gmail.com)",
    pay_fail_btntext: 'กลับไปที่หน้าแรก',
  },
  setting: {
    setting: 'การตั้งค่า',
    setting_title: 'จัดการบัญชี การใช้งาน และการสมัครสมาชิกของคุณที่นี่',
    basic_info: 'ข้อมูลพื้นฐาน', // 新增，放在 setting_title 下方
    user_name: 'ชื่อ',
    user_email: 'อีเมล',
    user_account: 'บัญชีผู้ใช้',
    user_subscript: 'แผนปัจจุบัน',
    user_usage: 'การใช้งาน',
    manage_subscript: 'จัดการการสมัครสมาชิก',
    use_limit_promote: "คุณได้ใช้ 5000 จากขีดจำกัดรายเดือนของคุณที่ 10000 ตัวอักษร",
    not_logged_in: 'คุณยังไม่ได้เข้าสู่ระบบ', // 新增
    go_to_login: 'ไปที่หน้าเข้าสู่ระบบ >>', // 新增
  },
  login: { // 新增 login 对象，放在 setting 下方
    title: "TikTok Voice Generator",
    subtitle: "เข้าสู่ระบบเพื่อเข้าถึงบัญชี TikTok Voice Generator ของคุณ",
    alreadyLoggedIn: "คุณเข้าสู่ระบบแล้ว",
    goToHome: "ไปที่หน้าหลัก"
  },
  faq: {
    quest1: 'ตัวสร้างเสียง TikTok - เครื่องมือเสียง AI TikTok ที่ดีที่สุด',
    answer1_1: 'นี่คือตัวสร้างสียง AI TikTok ี่พัฒนาด้วยเทคโนโลยี tts TikTok ล่าสุด สามารถสร้างเสียง TikTok เช่น เสียงของ Jessie (เสียงผู้หญิง), เสียงของ Siri, เสียงของ ghostface, เสียงของ C3PO (เสียงหุ่นยนต์), เสียงลึก (ผู้บรรยาย), เสียงของ Skye, เสียงอบอุ่น, เสียงของ Bestie, เสียงของฮีโร่ (เสียงของ Chiris), เสียงที่เข้าอกเข้าใจ, เสียงจริงจัง, เสียงของ Joey, เสียงของ Stitch, เสียงของ Stormtrooper (Star Wars), เสียงของ Rocket (Guardians of the Galaxy).',
    answer1_2: 'เสียง AI TikTok ที่จะรองรับเร็วๆ นี้ ได้แก่ เสียงของ Derek, เสียงของ Trickster, เสียงของ Austin Butler, เสียงผู้รายงานข่าว, เสียงของ Adam, เสียงของสุนัข, เสียงของ Miley Cyrus, เสียงของ Alexa, เสียงของเอเลี่ยน, เสียงของสัตว์, เสียงของเด็ก, เสียงของคอมพิวเตอร์, เสียงของ chipmunk, เสียงของ echo, เสียงของ npr.',
    answer1_3: 'นอกจากนี้ เครื่องมือตัวสร้างเสียง TikTok ยังรองรับหลายภาษา เช่น จีน, ญี่ปุ่น, เกาหลี, เวียดนาม, ไทย, ฮินดี, เปอร์เซีย, รัสเซีย, เยอรมัน, ฝรั่งเศส, โรมาเนีย, เช็ก, สเปน, โปรตุเกส, เบงกาลี, อิตาลี, อาหรับ, อูรดู, จีนดั้งเดิม และมาเลย์',
    answer1_4: 'หากคุณต้องการเสียงเฉพาะเจาะจงด่วน กรุณาส่งอีเมลถึงฉัน',

quest2: 'ข้อดีของตัวสร้างเสียง TikTok คืออะไร?',
    answer2_1: '- เครื่องมือตัวสร้างเสียง TikTok สามารถสร้างเสียงหลายประเภทที่มักใช้ในวิดีโอ TikTok.',
    answer2_2: '- นี่คือตัวสร้างเสียง AI ล่าสุดที่สามารถสร้างเสียง tts TikTok ที่เหมือนมนุษย์',
    answer2_3: '- สะดวกกว่าสำหรับการแก้ไขวิดีโอบนพีซี',
    answer2_4: '- คุณสามารถใช้เสียงบางประเภทที่ไม่มีในแอป tts TikTok ในปัจจุบัน',

quest3: 'วิธีการใช้ตัวสร้างเสียง TikTok?',
    answer3_1: 'การใช้ตัวสร้างเสียง TikTok นั้นง่ายมาก:',
    answer3_2: '- เลือกภาษาและสำเนียงของเสียง',
    answer3_3: '- พิมพ์ข้อความที่ต้องการแปลงเป็นเสียงในช่องป้อนข้อมูล',
    answer3_4: '- กดปุ่มสร้างและรอสักครู่',
    answer3_5: '- เล่นหรือดาวน์โหลดเสียง AI TikTok',

quest4: 'เสียง TikTok ที่มีชื่อเสียงและสนุกสนานที่สุดคืออะไร?',
    answer4_1: 'เสียงผู้ชายที่มีชื่อเสียงที่สุดใน TikTok คือเสียงลึก ซึ่งเรียกอย่างเป็นทางการว่าผู้บรรยาย',
    answer4_2: 'สำหรับเสียง TikTok ที่สนุกสนานที่สุด ฉันขอแนะนำเสียงของ ghostface และเสียงของ C3PO เป็นการส่วนตัว เสียงเหล่านี้มักใช้ในการพากย์วิดีโอ TikTok ที่สนุกสนาน เพราะสามารถจดจำได้ง่ายและดึงดูดผู้ชมได้อย่างรวดเร็ว',

quest5: 'เสียง TikTok ที่น่ารำคาญที่สุดคืออะไร?',
    answer5_1: 'ฉันเชื่อว่าไม่มีเสียง TikTok ที่ "น่ารำคาญ" จริงๆ',
    answer5_2: 'มันขึ้นอยู่กับบริบทและเนื้อหาที่ใช้เสียงมากกว่า ซึ่งอาจทำให้บางคนรู้สึกไม่สบายใจ ตัวอย่างเช่น บางคนอาจรู้สึกว่าเสียงของ Jessie น่ารำคาญเพราะมันปรากฏบ่อยเกินไปใน TikTok ในช่วงเวลาหนึ่ง อย่างไรก็ตาม เนื่องจากเสียงและคุณภาพของมัน จึงเป็นเอฟเฟกต์เสียง TikTok ที่ยอดเยี่ยม นอกจากนี้ นักพากย์เสียง TikTok ที่อยู่เบื้องหลังเสียงนี้มีความเป็นมืออาชีพมาก และเธอเองก็โพสต์วิดีโอของเธอบน TikTok',
    answer5_3: 'ดังนั้น ฉันขอแนะนำให้ทุกคนวางความกังวลและอคติไว้ข้างๆ ฟังเสียงจากเครื่องมือนี้หลายๆ ครั้ง และหาความอดทนเพื่อค้นหาเอฟเฟกต์เสียง TikTok ที่ดีที่สุดสำหรับคุณ',

quest6: 'วิธีใช้ตัวสร้างเสียง TikTok เพื่อสร้างเสียงของ Jessie?',
    answer6_1: '- ในเมนูแบบเลื่อนลงแรกของเครื่องมือตัวสร้างเสียง TikTok ให้เลือก English (US) จากนั้นในเมนูแบบเลื่อนลงอื่น เลือกเสียงของ Jessie (เสียงผู้หญิง)',
    answer6_2: '- พิมพ์ข้อความในช่องป้อนข้อมูลแล้วคลิกปุ่มสร้าง',
    answer6_3: '- รอไม่กี่วินาทีหรือไม่เกิน 10 วินาที คุณจะได้ยินเสียง AI ในแถบเครื่องมือ ให้หาปุ่มที่มีลูกศรลงแล้วคลิกเพื่อดาวน์โหลด',

quest7: 'วิธีใช้ตัวสร้างเสียง TikTok เพื่อสร้างเสียงของ C3PO?',
    answer7_1: '- ในเมนูแบบเลื่อนลงแรกของเครื่องมือตัวสร้างเสียง TikTok ให้เลือก English (US) จากนั้นในเมนูแบบเลื่อนลงอื่น เลือกเสียงของ C3PO (เสียงหุ่นยนต์)',
    answer7_2: '- พิมพ์ข้อความในช่องป้อนข้อมูลแล้วคลิกปุ่มสร้าง',
    answer7_3: '- รอไม่กี่วินาทีหรือไม่เกิน 10 วินาที คุณจะได้ยินเสียง AI ในแถบเครื่องมือ ให้หาปุ่มที่มีลูกศรลงแล้วคลิกเพื่อดาวน์โหลด',

quest8: 'วิธีใช้ตัวสร้างเสียง TikTok เพื่อสร้างเสียงของ ghostface?',
    answer8_1: '- ในเมนูแบบเลื่อนลงแรกของเครื่องมือตัวสร้างเสียง TikTok ให้เลือก English (US) จากนั้นในเมนูแบบเลื่อนลงอื่น เลือกเสียงของ ghostface',
    answer8_2: '- พิมพ์ข้อความในช่องป้อนข้อมูลแล้วคลิกปุ่มสร้าง',
    answer8_3: '- รอไม่กี่วินาทีหรือไม่เกิน 10 วินาที คุณจะได้ยินเสียง AI ในแถบเครื่องมือ ให้หาปุ่มที่มีลูกศรลงแล้วคลิกเพื่อดาวน์โหลด',

quest9: 'วิธีใช้ตัวสร้างเสียง TikTok เพื่อสร้างเสียงของ Siri?',
    answer9_1: '- ในเมนูแบบเลื่อนลงแรกของเครื่องมือตัวสร้างเสียง TikTok ให้เลือก English (US) จากนั้นในเมนูแบบเลื่อนลงอื่น เลือกเสียงของ Siri',
    answer9_2: '- พิมพ์ข้อความในช่องป้อนข้อมูลแล้วคลิกปุ่มสร้าง',
    answer9_3: '- รอไม่กี่วินาทีหรือไม่เกิน 10 วินาที คุณจะได้ยินเสียง AI ในแถบเครื่องมือ ให้หาปุ่มที่มีลูกศรลงแล้วคลิกเพื่อดาวน์โหลด',

quest10: 'วิธีเพิ่มเอฟเฟกต์เสียง TikTok ลงในวิดีโอ TikTokของฉัน?',
    answer10_1: 'หากคุณต้องการทราบวิธีการใช้เสียง TikTok อย่างเป็นทางการบน TikTok ฉันจะเขียนบทความเกี่ยวกับหัวข้อนี้ในไม่ช้า',
    answer10_2: 'ที่นี่ฉันจะอธิบายวิธีการโพสต์เสียงของคุณบน TikTok หลังจากสร้างและดาวน์โหลดจากตัวสร้างเสียง TikTok',
    answer10_3: '1. หากคุณสร้างเสียง TikTok บนพีซีและต้องการอัปโหลดไปยัง TikTok หรือแอปแก้ไขวิดีโออื่นๆ บนโทรศัพท์ของคุณ คุณจะต้องถ่ายโอนไฟล์เสียงไปยังโทรศัพท์ของคุณ สำหรับ iPhone คุณสามารถใช้ AirDrop ในการถ่ายโอนได้ สำหรับโทรศัพท์ Android ฉันไม่ค่อยคุ้นเคยกับกระบวนการนี้ แต่คุณจะพบวิธีและเครื่องมือที่จะทำได้อย่างแน่นอน',
    answer10_4: '2. เมื่อไฟล์เสียงถูกถ่ายโอนไปยังโทรศัพท์ของคุณแล้ว คุณสามารถเปิด TikTok ได้:',
    answer10_5: '- คลิกปุ่ม "+" ที่ด้านล่างของอินเตอร์เฟซ เลือกวิดีโอในโทรศัพท์ของคุณ',
    answer10_6: '- หลังจากอัปโหลดวิดีโอ ให้หาสัญลักษณ์สี่เหลี่ยมที่ด้านขวาของหน้าจอเพื่อเข้าสู่หน้าการแก้ไข',
    answer10_7: '- ที่ด้านล่างของหน้า ให้หาปุ่ม "เพิ่มเสียง" และคลิกเพื่อเลือกไฟล์เสียงที่เพิ่งถ่ายโอน',
    answer10_8: '- ในอินเตอร์เฟซนี้ คุณสามารถปรับแต่งวิดีโอได้ จากนั้นคลิกปุ่มที่มุมบนขวาเพื่อโพสต์',

quest11: 'TikTok Voice ฟรีหรือไม่?',
    answer11: 'ใช่ มันเป็นเครื่องมือสร้างเสียง AI TikTok ฟรีจากข้อความเป็นเสียง',

quest12: 'ฉันสามารถสร้างเสียง TikTok ยอดนิยมในวิดีโอ TikTok ของฉันได้ที่ไหน?',
    answer12: 'คุณสามารถเยี่ยมชม https://tiktokvoice.net/ เพื่อเพิ่มเสียง AI TikTok ลงในวิดีโอของคุณ'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}